import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import samer from "../images/samer.jpg"
import BioDataContentPropType from "../utils/bio-content-proptype"

const Bio = ({ data }) => {
  const {
    contentfulBio: {
      text: {
        // text,
        childMarkdownRemark: { html },
      },
    },
  } = data
  return (
    <Layout>
      <SEO title="Biography" />
      <div
        css={css`
          display: flex;
          justify-content: stretch;
          width: 95%;
          margin: 40px auto;

          @media (max-width: 640px) {
            flex-direction: column;
            margin: 20px auto;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
            padding: 0 30px;

            @media (max-width: 640px) {
              margin-bottom: 20px;
              margin: 0px auto 20px auto;
              padding: 0px;
            }
          `}
        >
          <img
            css={css`
              max-width: 100%;
            `}
            src={samer}
            alt="Samer"
          />
        </div>
        <div
          css={css`
            margin: 0px 0px;
            flex: 1;
            font-size: 16px;
            color: #f8f2f2;

            p {
              margin-top: 0px;
            }
          `}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

Bio.propTypes = BioDataContentPropType

export const query = graphql`
  query bio($lang: String = "en-US") {
    contentfulBio(bioId: { eq: "samer" }, node_locale: { eq: $lang }) {
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Bio
